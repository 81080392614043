import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Reasons For Using SIMTRIM" />
    <h1>Reasons For Using SIMTRIM</h1>
    <p>SIMTRIM™ is a patented method which allows the majority of currently manufactured conventional wood or MDF (medium density fiberboard) molding or trim to be completely removable and replaceable. Not only can the SIMTRIM™ system be used with the majority of new molding or trim, but it can also be used to make the majority of existing and previously installed molding or trim removable and replaceable.</p>
    <p>JUST SNAP ON AND SNAP OFF!</p>
    <ul>
      <li>Are you tired of masking baseboards, casings, wainscoting or crown molding when painting walls?</li>
      <li>Have you ever thought that it would be a real advantage if molding or trim was out of the way when you wall papered a room or refinished the wall in any way?</li>
      <li>Have you ever damaged your walls or damaged your molding or trim when you’ve had to remove baseboards, casings, wainscoting or crown moldings?</li>
      <li>Have you ever removed existing molding or trim and had to re-fill nail holes in the molding and then had to refinish the front surface of the old molding or trim?</li>
      <li>Does your present system of brad nailed molding and trim (especially on baseboards) end up pulling away from the wall because the finishing carpenters fail to hit any backing when brad nailing on your molding?</li>
      <li>Have you ever wanted to easily change the height of your baseboards to accommodate new flooring?</li>
      <li>Have you ever wanted to hide electrical, stereo, or telephone cables behind your baseboards?</li>
      <li>Have you ever wanted to use conventional wood or medium density fibreboard (MDF) molding or trim in a building that has metal studs in wall construction and you can’t use conventional wood or MDF molding or trim because brad nails don’t engage with metal stud backing?</li>
    </ul>
    <p>If you’ve answered yes to any of the above questions, then the SIMTRIM™ Molding System is for you.</p>
  </Layout>
)

export default SecondPage
